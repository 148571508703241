import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

import "../styles/index.scss"
import TwitterLogo from "../assets/twitter-2.svg"
import GithubLogo from "../assets/github-icon.svg"
import Head from "../components/head"

export default function Home() {
  return (
    <>
      <Head title="Home" />
      <Layout>
        <div className="content">
          <section className="hero">
            <img src="../assets" alt="" />
            <div className="hero-content">
              <h1>
                Passionate
                <br />
                FullStack Developer
              </h1>
              <p>
                I'm Cédric a FullStack developer. I've worked on many website
                projects and applications. I'm passionate about my work.
              </p>
              <Link to="/projects" className="action-btn">
                See My Projects
              </Link>
            </div>
          </section>
        </div>
        <div className="footer">
          <footer className="foot-icon">
            <ul>
              <p>Follow Me</p>
              <li>
                <a
                  href="https://twitter.com/Ced_Create"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="icon-svg"
                    src={TwitterLogo}
                    alt="Follow me on Twitter"
                  />
                </a>
              </li>
              <p>See My Repo</p>
              <li>
                <a
                  href="https://github.com/cedasie"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="icon-svg"
                    src={GithubLogo}
                    alt="See my Repo"
                  />
                </a>
              </li>
            </ul>
          </footer>
        </div>
      </Layout>
    </>
  )
}
